import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction(
  '[Sort Properties] Load Request',
  props<{ propertyIds: number[] }>(),
);

export const loadSuccess = createAction('[Sort Properties] Load Success');

export const loadFailure = createAction(
  '[Sort Properties] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Sort Properties] Reset State');

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { SortPropertiesSuccess } from '../../core/+state/core.actions';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { SortPropertiesService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class SortPropertiesStoreEffects {
  constructor(
    private dataService: SortPropertiesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyIds }) =>
        this.dataService.sort({ propertyIds }).pipe(
          mergeMap((response: IResponseSuccess) => [
            new SortPropertiesSuccess({ propertyIds }),
            fromActions.loadSuccess(),
          ]),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
